import {
  Outlet,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";
import { FCC } from "../utils/reactHelpers/types";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { Menu } from "../components/Menu/Menu";
import { Header } from "../components/Header/Header";
import { logout } from "../stores/auth/AuthStore.services";

export const Home: FCC = ({ children }) => {
  //   const navigate = useNavigate();
  //   const onLogout = async () => {
  //     await logout();
  //     navigate(`/login`);
  //   };

  const user = { firstName: "John", lastName: "Doe" };
  const onLogout = () => {
    console.log("logout");
  };

  const links = ["dashboard", "product-catalog", "notifications", "settings"];

  return (
    <>
      <Header user={user} onLogout={onLogout} />
      <div className="flex">
        <Menu links={links} />
        <Outlet />
      </div>
    </>
  );
};

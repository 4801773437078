import { FCC } from "../../../utils/reactHelpers/types";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import * as Switch from "@radix-ui/react-switch";
import { useState } from "react";
import clsx from "clsx";
import { Button } from "../../Button/Button";

export interface FeaturedItemProps {
  title?: string;
  description?: string;
  thumbnail?: string;
  active?: boolean;
  id: string;
}

export const FeaturedItem: FCC<FeaturedItemProps> = ({
  title,
  description,
  thumbnail,
  active,
  id,
}: FeaturedItemProps) => {
  const [isActive, setIsActive] = useState(active);
  return (
    <div className="w-80 rounded-md bg-white p-1">
      <AspectRatio.Root ratio={16 / 9}>
        <img
          className="h-full w-full object-cover"
          src={thumbnail}
          alt={title}
        />
      </AspectRatio.Root>

      <div>
        {title ? <div className="text-lg">{title}</div> : null}
        {description ? <div className="text-lg">{description}</div> : null}
      </div>

      <div
        className={
          isActive
            ? clsx("flex justify-between")
            : clsx("flex justify-between", "bg-tertiary-grey")
        }
      >
        <div> - </div>
        <div className="flex justify-end">
          <label className="" htmlFor={id}>
            {isActive ? "ON" : "OFF"}
          </label>
          <Switch.Root
            id={id}
            defaultChecked={isActive}
            onCheckedChange={setIsActive}
            className="w-10 h-6 bg-gray-400 rounded-full data-[state=checked]:bg-black outline-none"
          >
            <Switch.Thumb className="block w-5 h-5 bg-white rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
          </Switch.Root>
          <Button type="outlined-gray" label="Edit" />
        </div>
      </div>
    </div>
  );
};

import { ApiPaths, paths } from "./apiTypes";
import { createSendRequest } from "./createSendRequest";

export const sendPublicRequest = createSendRequest<paths, typeof ApiPaths>(
  ApiPaths,
  {
    // baseUrl: "http://localhost:3322",
    baseUrl: "https://api.squeeze.tk",
  }
);

export const sendRequest =
  (auth: string): typeof sendPublicRequest =>
  (operationId, request) =>
    sendPublicRequest(operationId, {
      ...request,
      header: {
        ...(request as any).header,
        Authorization: `Bearer ${auth}`,
      },
    });

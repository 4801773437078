import { Suspense, useState } from "react";
import { useLocation } from "react-router-dom";
import { FCC } from "../utils/reactHelpers/types";
import { Result, Spin } from "antd";
import { useQuery } from "@tanstack/react-query";
import { verifyShopAuthorization } from "../stores/auth/AuthStore.services";

export const AppAuthorizationChecker: FCC = ({ children }) => {
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const params = new URLSearchParams(search);
  const hmac = params.get("hmac");
  const shop = params.get("shop");

  useQuery(
    ["AuthStore_verifyShopAuthorization", params],
    async () => {
      return await verifyShopAuthorization(params);
    },
    {
      enabled: hmac != null && shop != null,
      onSuccess: async (authState) => {
        setIsLoading(false);

        const params = new URLSearchParams();
        params.set("client_id", authState.clientId);
        params.set("scope", authState.scope);
        params.set("state", authState.nonce);
        params.set(
          "redirect_uri",
          `https://${window.location.hostname}/auth_callback`
        );

        // if already have store's offline token, then only get online token per user based
        if (authState.authorized) {
          params.set("grant_options[]", "per-user");
        }

        const authorizeUrl = `https://${shop}/admin/oauth/authorize?${params.toString()}`;
        window.location.replace(authorizeUrl);
      },
    }
  );

  if (hmac == null || shop == null) {
    return <Suspense>{children}</Suspense>;
  }

  if (isLoading) {
    return <Spin />;
  } else {
    return (
      <Result
        status="success"
        title="Redirecting to authorization page..."
        subTitle="please wait."
      />
    );
  }
};

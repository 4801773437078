import { FCC } from "../../utils/reactHelpers/types";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom";
import clsx from "clsx";

interface MenuProps {
  links: string[];
}

function CustomNavMenuItem({ children, to, ...props }: LinkProps) {
  const defaultClasses = "h-10 leading-10 rounded-lg hover:bg-violet-200 my-1";

  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  const classes = clsx(match ? "bg-violet-200" : "", defaultClasses);

  return (
    <NavigationMenu.Item className={classes}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </NavigationMenu.Item>
  );
}

export const Menu: FCC<MenuProps> = ({ links }) => {
  return (
    <NavigationMenu.Root orientation="vertical" className="p-4 w-48">
      <NavigationMenu.List>
        {links.map((link) => {
          return <CustomNavMenuItem to={`/${link}`}>{link}</CustomNavMenuItem>;
        })}
      </NavigationMenu.List>
      <NavigationMenu.Viewport />
    </NavigationMenu.Root>
  );
};

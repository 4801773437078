import type firebase from "firebase/compat/app";
import { map, Observable } from "rxjs";
import { FirestoreCollectionPathTypes } from "./firestoreTypes";

export type DocumentReference<T = any> =
  firebase.firestore.DocumentReference<T>;
export type DocumentSnapshot<T = any> = firebase.firestore.DocumentSnapshot<T>;
export type QueryDocumentSnapshot<T = any> =
  firebase.firestore.QueryDocumentSnapshot<T>;

export type CollectionReference<T = any> = Query<T> &
  Omit<firebase.firestore.CollectionReference<T>, "doc" | "where" | "orderBy">;

type WhereValue<
  Op extends firebase.firestore.WhereFilterOp,
  Value
> = Op extends "in" | "array-contains-any"
  ? Value[]
  : Op extends "array-contains"
  ? Value extends (infer ValueElement)[]
    ? ValueElement
    : Value
  : Value;

export type Query<T = firebase.firestore.DocumentData> = Omit<
  firebase.firestore.Query<T>,
  "where" | "orderBy"
> & {
  where<Op extends firebase.firestore.WhereFilterOp>(
    fieldPath: firebase.firestore.FieldPath,
    opStr: Op,
    value: WhereValue<Op, string>
  ): Query<T>;
  where<K extends keyof T, Op extends firebase.firestore.WhereFilterOp>(
    fieldPath: K,
    opStr: Op,
    value: WhereValue<Op, T[K]>
  ): Query<T>;
  where<
    K extends keyof T,
    KK extends keyof T[K],
    Op extends firebase.firestore.WhereFilterOp
  >(
    fieldPath: `${string & K}.${string & KK}`,
    opStr: Op,
    value: WhereValue<Op, T[K][KK]>
  ): Query<T>;
  where<
    K extends keyof T,
    KK extends keyof T[K],
    KKK extends keyof T[K][KK],
    Op extends firebase.firestore.WhereFilterOp
  >(
    fieldPath: `${string & K}.${string & KK}.${string & KKK}`,
    opStr: Op,
    value: WhereValue<Op, T[K][KK][KKK]>
  ): Query<T>;
  orderBy(
    fieldPath: firebase.firestore.FieldPath,
    directionStr?: "asc" | "desc"
  ): Query<T>;
  orderBy<K extends keyof T>(
    fieldPath: K,
    directionStr?: "asc" | "desc"
  ): Query<T>;
  orderBy<K extends keyof T, KK extends keyof T[K]>(
    fieldPath: `${string & K}.${string & KK}`,
    directionStr?: "asc" | "desc"
  ): Query<T>;
  orderBy<K extends keyof T, KK extends keyof T[K], KKK extends keyof T[K][KK]>(
    fieldPath: `${string & K}.${string & KK}.${string & KKK}`,
    directionStr?: "asc" | "desc"
  ): Query<T>;
};

export interface CollectionsDefinition {
  [key: string]: {
    type: any;
    collections?: CollectionsDefinition;
    docIds?: string;
  };
}

export type EnrichFirestore<T extends CollectionsDefinition> = {
  collection<K extends keyof T>(
    collection: K
  ): {
    doc(
      id?: T[K]["docIds"] | string
    ): EnrichFirestore<NonNullable<T[K]["collections"]>> &
      DocumentReference<T[K]["type"]>;
  } & CollectionReference<T[K]["type"]> &
    Query<T[K]["type"]>;
};

export type EnhancedFirestoreType = Omit<
  firebase.firestore.Firestore,
  "collection"
> &
  EnrichFirestore<FirestoreCollectionPathTypes>;

export function collectionData$<T extends firebase.firestore.DocumentData>(
  query: Query<T>
): Observable<QueryDocumentSnapshot<T>[]> {
  return new Observable<firebase.firestore.QuerySnapshot<T>>((subscriber) => {
    const sub = query.onSnapshot(subscriber);
    return { unsubscribe: sub };
  }).pipe(map((snap) => snap.docs));
}

export function docDataWith$<T extends firebase.firestore.DocumentData>(
  doc: DocumentReference<T>
): Observable<DocumentSnapshot<T>> {
  return new Observable<firebase.firestore.DocumentSnapshot<T>>(
    (subscriber) => {
      const sub = doc.onSnapshot(subscriber);
      return { unsubscribe: sub };
    }
  );
}

import type { User } from "firebase/auth";
import { defer, Observable, shareReplay } from "rxjs";
import { sendPublicRequest } from "../../generated/api";
import { auth } from "../firebase/firebaseServices";

export const authState$: Observable<User | null> = defer(
  () =>
    new Observable<User | null>(function (subscriber) {
      const unsubscribe = auth.onAuthStateChanged({
        next: subscriber.next.bind(subscriber),
        error: subscriber.error.bind(subscriber),
        complete: subscriber.complete.bind(subscriber),
      });
      return { unsubscribe };
    })
).pipe(shareReplay(1));

export async function logout(): Promise<void> {
  await auth.signOut();
}

export async function verifyShopAuthorization(queryParams: URLSearchParams) {
  const result = await sendPublicRequest(
    "ShopifyOAuthController_verifyShopAuthorization",
    {
      query: Object.fromEntries(queryParams),
    }
  );

  return result.data;
}

export async function handleOAuthCallback(queryParams: URLSearchParams) {
  const result = await sendPublicRequest(
    "ShopifyOAuthController_handleAuthorizationCallback",
    {
      query: Object.fromEntries(queryParams),
    }
  );

  return result.data;
}

import { reaction } from "mobx";
import { Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../stores/auth/useAuthStore";
import { waitFor } from "../stores/utils/waitFor";
import { noop } from "../utils/fnHelpers";
import { FCC } from "../utils/reactHelpers/types";

export const AuthenticationChecker: FCC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const auth = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    return reaction(
      () => auth.isLoggedIn$,
      async (isLoggedIn) => {
        console.log("isLoggedIn", isLoggedIn);
        if (isLoggedIn) {
          navigate(
            `/dashboard?shop=${await waitFor(
              () => auth.loggedInUserProfile$.shop
            )}`
          );
        } else {
          navigate(`/login`);
        }
        setIsLoading(false);
      },
      {
        onError: noop,
      }
    );
  }, [auth, navigate]);

  return isLoading ? (
    <div>Please wait...</div>
  ) : (
    <Suspense>{children}</Suspense>
  );
};

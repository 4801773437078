import { FCC } from "../../utils/reactHelpers/types";

export interface ButtonProps {
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;

  type: "outlined-gray" | "outlined-violet" | "underlined-transparent";
}

const buttonStyles = {
  "outlined-gray":
    "p-2 bg-white rounded-md text-sm shadow-sm text-secondary-grey border-2 border-secondary-grey",
  "outlined-violet":
    "p-2 bg-white rounded-md text-sm shadow-sm text-primary-blue border-2 border-primary-blue",
  "underlined-transparent": "underline hover:underline-offset-4",
};

/**
 * Button UI component
 */
export const Button: FCC<ButtonProps> = ({
  label,
  type,
  ...props
}: ButtonProps) => {
  return (
    <button className={buttonStyles[type]} type="button" {...props}>
      {label}
    </button>
  );
};

import { TabContainer } from "../components/Tab/TabContainer";
import { Table } from "../components/Table/Table";
import { FCC } from "../utils/reactHelpers/types";

export const Notification: FCC = ({ children }) => {
  return (
    <TabContainer>
      <Table />
    </TabContainer>
  );
};

import { FCC } from "../../../utils/reactHelpers/types";
import update from "immutability-helper";
import { useState } from "react";
import { DragAndDropWrapper } from "../DragAndDropWrapper";
import { CardContainer, CardContainerProps } from "../CardContainer";
import { Button } from "../../Button/Button";
import { CatalogItem, CatalogItemProps } from "./CatalogItem";

export interface CatalogItemsCardProps {
  catalogItems: CatalogItemProps[];
  containerProps?: CardContainerProps;
}

export const CatalogItemsCard: FCC<CatalogItemsCardProps> = ({
  catalogItems,
  containerProps,
}: CatalogItemsCardProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const [items, setItems] = useState(catalogItems);

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    setItems((prevItems: CatalogItemProps[]) =>
      update(prevItems, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevItems[dragIndex] as CatalogItemProps],
        ],
      })
    );
  };

  const renderItem = (item: CatalogItemProps, index: number) => {
    return (
      <DragAndDropWrapper
        key={item.id}
        id={item.id}
        index={index}
        moveItem={moveItem}
      >
        <CatalogItem {...item} />
      </DragAndDropWrapper>
    );
  };

  return (
    <CardContainer {...containerProps}>
      <div className="flex justify-between">
        <span>Catalog Items</span>
        <Button
          type="outlined-violet"
          label={"+ New Catalog Item"}
          onClick={() => console.log("+ New Catalog Item clicked!")}
        />
      </div>
      <div className="grid grid-cols-1">
        {collapsed
          ? items?.slice(0, 6).map((item, index) => renderItem(item, index))
          : items?.map((item, index) => renderItem(item, index))}
      </div>
      <div className="flex justify-center">
        <Button
          type="outlined-gray"
          label={collapsed ? `Show More (${items.length})` : "Show Less"}
          onClick={() => setCollapsed(!collapsed)}
        />
      </div>
    </CardContainer>
  );
};

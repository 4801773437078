import { FCC } from "../../../utils/reactHelpers/types";
import update from "immutability-helper";
import { useState } from "react";
import { FeaturedItem, FeaturedItemProps } from "./FeaturedItem";
import { DragAndDropWrapper } from "../DragAndDropWrapper";
import { CardContainer, CardContainerProps } from "../CardContainer";
import { Button } from "../../Button/Button";

export interface FeaturedItemsCardProps {
  featuredItems: FeaturedItemProps[];
  containerProps?: CardContainerProps;
}

export const FeaturedItemsCard: FCC<FeaturedItemsCardProps> = ({
  featuredItems,
  containerProps,
}: FeaturedItemsCardProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const [items, setItems] = useState(featuredItems);

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    setItems((prevItems: FeaturedItemProps[]) =>
      update(prevItems, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevItems[dragIndex] as FeaturedItemProps],
        ],
      })
    );
  };

  const renderItem = (item: FeaturedItemProps, index: number) => {
    return (
      <DragAndDropWrapper
        key={item.id}
        id={item.id}
        index={index}
        moveItem={moveItem}
      >
        <FeaturedItem {...item} />
      </DragAndDropWrapper>
    );
  };

  return (
    <CardContainer {...containerProps}>
      <div className="flex justify-between">
        <span>Featured Items</span>
        <Button
          type="outlined-violet"
          label={"+ New Featured Item"}
          onClick={() => console.log("+ New Featured Item clicked!")}
        />
      </div>
      <div className="grid gap-4 grid-cols-3">
        {collapsed
          ? items?.slice(0, 6).map((item, index) => renderItem(item, index))
          : items?.map((item, index) => renderItem(item, index))}
      </div>
      <div className="flex justify-center">
        <Button
          type="outlined-gray"
          label={collapsed ? `Show More (${items.length})` : "Show Less"}
          onClick={() => setCollapsed(!collapsed)}
        />
      </div>
    </CardContainer>
  );
};

import { Result, Spin } from "antd";
import { Suspense, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { handleOAuthCallback } from "../stores/auth/AuthStore.services";
import { FCC } from "../utils/reactHelpers/types";
import { auth } from "../stores/firebase/firebaseServices";

export const AuthCallback: FCC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(search);

  const isHandleOAuthCallback = (pathname: string) => {
    return pathname === "/auth_callback";
  };

  useQuery(
    ["ShopifyOAuthController_callback", params],
    async () => {
      return await handleOAuthCallback(params);
    },
    {
      enabled: isHandleOAuthCallback(pathname),
      onSuccess: async (d) => {
        setIsLoading(false);
        if (d.custom_token != null) {
          await auth.signInWithCustomToken(d.custom_token);
          navigate(`/dashboard?shop=${params.get("shop")}`);
        } else {
          navigate(`/`);
        }
      },
    }
  );

  if (isHandleOAuthCallback(pathname)) {
    if (isLoading) {
      return <Spin />;
    } else {
      return (
        <Result
          status="success"
          title="Authorization Success!"
          subTitle="please wait."
        />
      );
    }
  } else {
    return <Suspense>{children}</Suspense>;
  }
};

import { FCC } from "../../utils/reactHelpers/types";
import { Button } from "../Button/Button";

type User = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

interface HeaderProps {
  user?: User;
  onLogout?: () => void;
}

export const Header: FCC<HeaderProps> = ({ user, onLogout }: HeaderProps) => {
  return (
    <header>
      <div className="flex justify-between py-3 px-6">
        <div className="flex-none">
          <span className="font-bold">SQUEEZE</span>
        </div>
        {user && (
          <div className="flex-none">
            <span className="mr-6">
              <span className="text-secondary-grey">Logged in as: </span>
              {user?.firstName} {user?.lastName}
            </span>
            <Button
              type="underlined-transparent"
              label={"Log Out"}
              onClick={onLogout}
            />
          </div>
        )}
      </div>
    </header>
  );
};

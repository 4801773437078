import { TabContainer } from "../components/Tab/TabContainer";
import { FCC } from "../utils/reactHelpers/types";
import { range } from "ramda";
import { FeaturedItemsCard } from "../components/Card/FeaturedItem/FeaturedItemsCard";
import { CatalogItemsCard } from "../components/Card/CatalogItem/CatalogItemsCard";

export const ProductCatalog: FCC = ({ children }) => {
  const onLogout = () => {
    console.log("logout");
  };

  const featuredItems = range(0, 9).map((i) => ({
    title: `Title ${i}`,
    description: `description ${i}`,
    thumbnail:
      "https://images.unsplash.com/photo-1535025183041-0991a977e25b?w=300&dpr=2&q=80",
    alt: "Landscape photograph by Tobias Tullius",
    active: true,
    id: `${i}`,
  }));

  const catalogItems = range(0, 9).map((i) => ({
    id: `${i}`,
    thumbnail:
      "https://images.unsplash.com/photo-1535025183041-0991a977e25b?w=300&dpr=2&q=80",
    title: `Title ${i}`,
    type: "Lorem ipsum dolor sit amet",
    linkTo: "Est natus voluptate qui",
    linkToHref: "https://www.google.com/",
    active: true,
  }));

  return (
    <TabContainer>
      <div className="text-xl">Product Catalog</div>
      <FeaturedItemsCard featuredItems={featuredItems} />
      <CatalogItemsCard catalogItems={catalogItems} />
    </TabContainer>
  );
};

import { computed, makeObservable } from "mobx";
import { LazyValue } from "../utils/LazyValue/LazyValue";
import { authState$ } from "./AuthStore.services";
import type { User } from "firebase/auth";
import { waitUntilExist$ } from "../utils/waitFor";
import { from, map } from "rxjs";
import { firestore } from "../firebase/firebaseServices";
import { docDataWith$ } from "../firebase/firestore";
import { Profile } from "../firebase/model";

export class AuthStore {
  constructor() {
    makeObservable(this);
  }

  private authState = new LazyValue(
    () => null,
    () => authState$
  );

  @computed get loggedInUser$(): User {
    return waitUntilExist$(() => this.authState.value$);
  }

  @computed get isLoggedIn$() {
    return this.authState.value$ != null;
  }

  private firebaseToken = new LazyValue(
    () => [this.loggedInUser$] as const,
    ([user]) => from(user.getIdToken())
  );

  @computed get firebaseToken$(): string {
    return this.firebaseToken.value$;
  }

  private readonlyProfile = new LazyValue(
    () => [this.loggedInUser$] as const,
    ([user]) => {
      const docRef = docDataWith$(
        firestore.collection("readonlyProfile").doc(user.uid)
      ).pipe(map((a) => a.data()));
      return docRef;
    }
  );

  @computed get loggedInUserProfile$(): Profile {
    return waitUntilExist$(() => this.readonlyProfile.value$);
  }
}

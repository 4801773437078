import { FCC } from "../../../utils/reactHelpers/types";
import { CardContainer, CardContainerProps } from "../CardContainer";

export interface MetricsCardProps {
  title?: string;
  subtitle?: string;
  content?: React.ReactNode | React.ReactNode[];
  containerProps?: CardContainerProps;
}

export const MetricsCard: FCC<MetricsCardProps> = ({
  title,
  subtitle,
  content,
  containerProps,
}: MetricsCardProps) => {
  return (
    <CardContainer {...containerProps}>
      {title ? <div className="font-medium text-lg pb-2">{title}</div> : null}
      {subtitle ? (
        <div className="font-extrabold text-lg pb-4">{subtitle}</div>
      ) : null}
      <div>{content}</div>
    </CardContainer>
  );
};

import clsx from "clsx";
import { Suspense } from "react";
import { FCC } from "../../utils/reactHelpers/types";

export interface CardContainerProps {
  customClasses?: {
    classes?: string;
    override?: boolean;
  };
}

export const CardContainer: FCC<CardContainerProps> = ({
  children,
  customClasses,
}) => {
  const defaultClasses = "h-full bg-white border-solid border-2 rounded-lg p-4";
  const classes = customClasses
    ? customClasses.override
      ? clsx(customClasses.classes)
      : clsx(customClasses.classes, defaultClasses)
    : defaultClasses;
  return (
    <div className={classes}>
      <Suspense>{children}</Suspense>
    </div>
  );
};

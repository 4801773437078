import { CardContainer } from "../components/Card/CardContainer";
import { BarChart } from "../components/Card/Metrics/Chart/BarChart";
import { LineChart } from "../components/Card/Metrics/Chart/LineChart";
import { MetricsCard } from "../components/Card/Metrics/MetricsCard";
import { DateRangeSelector } from "../components/Selector/DateRangeSelector";
import { TabContainer } from "../components/Tab/TabContainer";
import { FCC } from "../utils/reactHelpers/types";

export const Dashboard: FCC = ({ children }) => {
  const dateRangeOnChange = (value: string) => {
    console.log(`date range changed to: ${value}`);
  };
  const dateRange = ["24h", "7d", "14d", "1m", "3m", "12m", "all", "custom"];
  return (
    <TabContainer>
      <div className="flex justify-between mb-6">
        <div className="text-xl">Dashboard</div>
        <DateRangeSelector onChange={dateRangeOnChange} values={dateRange} />
      </div>
      <div className="grid grid-flow-row-dense grid-cols-3 gap-6">
        <MetricsCard
          title={"Lorem Ipsum"}
          subtitle={"$4086"}
          content={<LineChart />}
          containerProps={{ customClasses: { classes: "col-span-2" } }}
        />
        <MetricsCard
          title={"Lorem Ipsum"}
          subtitle={"$4086"}
          content={<LineChart />}
        />
        <MetricsCard
          title={"Lorem Ipsum"}
          subtitle={"$4086"}
          content={<LineChart />}
        />
        <MetricsCard
          title={"Lorem Ipsum"}
          subtitle={"$4086"}
          content={<LineChart />}
        />
        <MetricsCard
          title={"Lorem Ipsum"}
          subtitle={"$4086"}
          content={<BarChart />}
        />
      </div>
    </TabContainer>
  );
};

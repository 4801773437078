import * as Switch from "@radix-ui/react-switch";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import { useState } from "react";
import { FCC } from "../../../utils/reactHelpers/types";
import { Button } from "../../Button/Button";

export interface CatalogItemProps {
  id: string;
  thumbnail?: string;
  title: string;
  type: string;
  linkTo: string;
  linkToHref?: string;
  active?: boolean;
}

export const CatalogItem: FCC<CatalogItemProps> = ({
  id,
  thumbnail,
  title,
  type,
  linkTo,
  linkToHref,
  active,
}: CatalogItemProps) => {
  const [isActive, setIsActive] = useState(active);

  return (
    <div className="flex justify-between">
      <div>-</div>
      <div className="flex">
        <img className="w-10 h-10 object-cover" src={thumbnail} alt={title} />
        <span>{title}</span>
      </div>

      <span>{type}</span>
      <a href={linkToHref}>{linkTo}</a>

      <div className="flex">
        <label htmlFor={id}>{isActive ? "ON" : "OFF"}</label>
        <Switch.Root
          id={id}
          defaultChecked={isActive}
          onCheckedChange={setIsActive}
          className="w-10 h-6 bg-gray-400 rounded-full data-[state=checked]:bg-black outline-none"
        >
          <Switch.Thumb className="block w-5 h-5 bg-white rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
        </Switch.Root>
        <Button type="outlined-gray" label="Edit" />
      </div>
    </div>
  );
};

import { Suspense } from "react";
import { FCC } from "../../utils/reactHelpers/types";

export const TabContainer: FCC = ({ children }) => {
  return (
    <div className="w-full py-6 px-4">
      <Suspense>{children}</Suspense>
    </div>
  );
};

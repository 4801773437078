import { Button, Form, Input } from "antd";
import { verifyShopAuthorization } from "../stores/auth/AuthStore.services";
import { FCC } from "../utils/reactHelpers/types";

export const Login: FCC = ({ children }) => {
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const authParams = new URLSearchParams();
    authParams.set("shop", values.storename);
    const authState = await verifyShopAuthorization(authParams);

    const params = new URLSearchParams();
    params.set("client_id", authState.clientId);
    params.set("scope", authState.scope);
    params.set("state", authState.nonce);
    params.set(
      "redirect_uri",
      `https://${window.location.hostname}/auth_callback`
    );

    // if already have store's offline token, then only get online token per user based
    if (authState.authorized) {
      params.set("grant_options[]", "per-user");
    }

    const authorizeUrl = `https://${
      values.storename
    }/admin/oauth/authorize?${params.toString()}`;
    window.location.replace(authorizeUrl);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFill = () => {
    form.setFieldsValue({
      storename: "just-a-random-neighborhood-convenience-store.myshopify.com",
    });
  };

  return (
    <div className="m-auto relative w-1/2 top-1/2">
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="storename"
          rules={[
            {
              required: true,
              message:
                "Please input your store name in form of xxx.myshopify.com!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <div className="flex justify-center">
            <Button type="primary" htmlType="submit" className="bg-indigo-600">
              Login via Shopify
            </Button>
            <Button type="link" htmlType="button" onClick={onFill}>
              Fill form
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

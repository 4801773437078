import {
  action,
  computed,
  IReactionOptions,
  makeObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
} from "mobx";
import { Observable } from "rxjs";
import { defer, isPromiseLike } from "../../../utils/promiseHelpers";

type LazyValueState<T> =
  | { type: "idle"; defer: defer.Deferred<void> }
  | { type: "value"; value: T }
  | { type: "error"; error: any };

export class LazyValue<Value extends any, Params> {
  @observable.ref private state: LazyValueState<Value> = {
    type: "idle",
    defer: defer(),
  };

  @computed
  get hasValue(): boolean {
    return this.state.type === "value";
  }

  get value$(): Value {
    if (this.state.type === "idle") {
      throw this.state.defer.promise;
    }
    if (this.state.type === "error") {
      throw this.state.error;
    }
    return this.state.value;
  }

  constructor(
    private readonly paramsGetter$: () => Params,
    private readonly fetchValue: (params: Params) => Observable<Value>,
    options?: {
      reactionOptions?: IReactionOptions<Params, boolean>;
      debug?: boolean | string;
    }
  ) {
    makeObservable(this);

    const trace = (msg: string, ...extraArgs: any[]): void => {
      if (options?.debug) {
        console.log(`${options?.debug || "LazyValue"} ${msg}`, ...extraArgs);
      }
    };

    trace("constructor");

    let dispose: () => void;
    onBecomeObserved(this, "state", async () => {
      trace("become observed");
      dispose = reaction(
        () => this.paramsGetter$(),
        (params) => {
          trace("params changed", params);
          void this.updateValue(params);
        },
        {
          ...options?.reactionOptions,
          fireImmediately: false,
          onError: (error) => {
            if (isPromiseLike(error)) {
              this.onReinitialize();
            }
          },
        }
      );
      void this.triggerUpdate().catch(() => null);
    });
    onBecomeUnobserved(this, "state", () => {
      trace("become unobserved");
      dispose?.();
    });
  }

  private unsubscribeLatestFetchValueCall?: () => void;
  private updateValue = (params: Params): Promise<void> => {
    this.unsubscribeLatestFetchValueCall?.();

    const source = this.fetchValue(params);

    const handleResult = this.updateValueFromObservable(source);

    this.unsubscribeLatestFetchValueCall = handleResult.unsubscribe;
    return handleResult.promise;
  };

  private updateValueFromObservable(
    observable: Observable<Value>
  ): FetchValueSourceHandleResult {
    const deferred = defer();
    const sub = observable.subscribe({
      next: (value) => {
        this.onReceiveValue(value);
        deferred.resolve();
      },
      error: (err) => {
        this.onEncounterError(err);
        deferred.reject(err);
      },
    });
    return {
      promise: deferred.promise,
      unsubscribe: () => {
        deferred.resolve();
        sub.unsubscribe();
      },
    };
  }

  @action private onReinitialize(): void {
    if (this.state.type !== "idle") {
      this.state = { type: "idle", defer: defer() };
    }
  }

  @action private onReceiveValue(value: Value): void {
    if (this.state.type === "idle") {
      this.state.defer.resolve();
    }
    this.state = { type: "value", value };
  }

  @action private onEncounterError(error: any): void {
    if (this.state.type === "idle") {
      this.state.defer.resolve();

      /**
       * only goto error state if previous state is idle
       * we don't want UI to go from success to error on updates like block height change
       */
      this.state = { type: "error", error };
    }
  }

  async triggerUpdate(): Promise<void> {
    await this.updateValue(this.paramsGetter$());
  }
}

interface FetchValueSourceHandleResult {
  promise: Promise<void>;
  unsubscribe: () => void;
}

import { FC, Suspense } from "react";
import { AuthStoreProvider } from "./stores/auth/useAuthStore";
import { FCC } from "./utils/reactHelpers/types";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthCallback } from "./auth/AuthCallback";
import { QueryProvider } from "./common/QueryProvider";
import { AppAuthorizationChecker } from "./auth/AppAuthorizationChecker";
import { Login } from "./auth/LoginPage";
import { Dashboard } from "./tabs/Dashboard";
import { AuthenticationChecker } from "./auth/AuthenticationChecker";
import { Notification } from "./tabs/Notification";
import { Settings } from "./tabs/Settings";
import { Home } from "./tabs/Home";
import { ProductCatalog } from "./tabs/ProductCatalog";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const StoreProviders: FCC = ({ children }) => (
  <AuthStoreProvider>
    <Suspense>{children}</Suspense>
  </AuthStoreProvider>
);

const App: FC = () => {
  return (
    <BrowserRouter>
      <StoreProviders>
        <QueryProvider>
          <AuthCallback>
            <AppAuthorizationChecker>
              <AuthenticationChecker>
                <DndProvider backend={HTML5Backend}>
                  <Routes>
                    <Route path="/" element={<Home />}>
                      <Route index element={<Dashboard />} />
                      <Route path="dashboard" element={<Dashboard />} />
                      <Route
                        path="product-catalog"
                        element={<ProductCatalog />}
                      />
                      <Route path="notifications" element={<Notification />} />
                      <Route path="settings" element={<Settings />} />
                    </Route>
                    <Route path="/login" element={<Login />} />
                  </Routes>
                </DndProvider>
              </AuthenticationChecker>
            </AppAuthorizationChecker>
          </AuthCallback>
        </QueryProvider>
      </StoreProviders>
    </BrowserRouter>
  );
};

export default App;

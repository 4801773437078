import { FCC } from "../../utils/reactHelpers/types";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { useState } from "react";
import { capitalized } from "../../utils/fnHelpers";

export interface DateRangeSelectorProps {
  onChange: (value: string) => void;
  values: string[];
}

export const DateRangeSelector: FCC<DateRangeSelectorProps> = ({
  onChange,
  values,
}) => {
  const [value, setValue] = useState(values[0]);

  const toggleGroupItemClasses =
    "data-[state=on]:underline underline-offset-8 decoration-4 data-[state=on]:text-violet-500";
  return (
    <ToggleGroup.Root
      className="inline-flex gap-4"
      type="single"
      value={value}
      onValueChange={(v) => {
        if (v) setValue(v);
        onChange(v);
      }}
    >
      {values.map((v, i) => {
        return (
          <ToggleGroup.Item className={toggleGroupItemClasses} value={v}>
            {capitalized(v)}
          </ToggleGroup.Item>
        );
      })}
    </ToggleGroup.Root>
  );
};
